import * as React from "react";

import Layout from "../components/Layout";

const MyStoryPage = () => {
  return (
    <Layout seo={{ title: "Journal" }}>
      <div className="p-6 prose dark:prose-dark prose-sm sm:prose lg:prose mx-auto">
        <p className="lead">My Story</p>
        <p>Coming soon </p>
      </div>
    </Layout>
  );
};

export default MyStoryPage;
